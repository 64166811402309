import { assertNever } from "@endearhq/toolchest-utilities";

import packageConfig from "../package.json";
import { iframeNavigate } from "./services/router-utils";

export const ASSETS_URL = asString(
  import.meta.env.VITE_ASSETS_URL,
  "VITE_ASSETS_URL",
);

export const APPLICATION_URL = asString(
  import.meta.env.VITE_APPLICATION_URL,
  "VITE_APPLICATION_URL",
);

export const EMBEDDED_APPLICATION_URL = asString(
  import.meta.env.VITE_EMBEDDED_APPLICATION_URL,
  "VITE_EMBEDDED_APPLICATION_URL",
);

export const SENTRY_DSN = asStringOr(
  import.meta.env.VITE_SENTRY_DSN,
  undefined,
);

export const RELEASE = packageConfig.version;

export const SECURE = asBoolean(import.meta.env.VITE_SECURE, "VITE_SECURE");

export const DOPPLER_ENVIRONMENT = asString(
  import.meta.env.VITE_DOPPLER_ENVIRONMENT,
  "VITE_DOPPLER_ENVIRONMENT",
  ["dev", "stg", "prd"],
);

export const DOPPLER_CONFIG = asString(
  import.meta.env.VITE_DOPPLER_CONFIG,
  "VITE_DOPPLER_CONFIG",
  ["dev", "dev_preview", "dev_tunnel", "stg", "prd", "prd_loc"],
);

export const FEATURE_FLAGS = constructFeatureFlagsForEnvironment([
  "appointment_scheduling",
  "appointment_scheduling_campaigns",
  "manager_approved_campaigns",
]);

export type FeatureFlags = Partial<
  Record<(typeof FEATURE_FLAGS)[number]["flag"], boolean>
>;

export const ASSET_PREFIX = asString(
  import.meta.env.VITE_ASSET_PREFIX,
  "VITE_ASSET_PREFIX",
);

export const TITLE = asString(import.meta.env.VITE_TITLE, "VITE_TITLE");

export const assets = {
  alt: TITLE,
  mark: `${ASSETS_URL}/logo/${ASSET_PREFIX}.svg?v=lk3klsk3lsk`,
};

export const STORIES_APP_URL = (
  subdomain: string,
  rootDomain = "endear.store",
) => {
  if (
    import.meta.env.VITE_STORIES_APP_URL &&
    typeof import.meta.env.VITE_STORIES_APP_URL === "string"
  ) {
    return import.meta.env.VITE_STORIES_APP_URL;
  }

  return `https://${subdomain}.${rootDomain}`;
};

export const SHORT_LINK_URL = asString(
  import.meta.env.VITE_SHORT_LINK_URL,
  "VITE_SHORT_LINK_URL",
);

export const INTEGRATIONS_OAUTH_URL = asString(
  import.meta.env.VITE_INTEGRATIONS_URL,
  "VITE_INTEGRATIONS_URL",
);

export const UPLOADCARE = {
  PUBLIC_KEY: asString(
    import.meta.env.VITE_UPLOADCARE_API_KEY,
    "VITE_UPLOADCARE_API_KEY",
  ),
  CDN_BASE: asString(
    import.meta.env.VITE_UPLOADCARE_CDN_BASE,
    "VITE_UPLOADCARE_CDN_BASE",
  ),
};

export const SEGMENT_WRITE_KEY = asString(
  import.meta.env.VITE_SEGMENT_WRITE_KEY,
  "VITE_SEGMENT_WRITE_KEY",
);

export const MARKETING_REDIRECT_URL = asString(
  import.meta.env.VITE_MARKETING_REDIRECT_URL,
  "VITE_MARKETING_REDIRECT_URL",
);

export const FIREBASE_PROJECT_ID = asString(
  import.meta.env.VITE_FIREBASE_PROJECT_ID,
  "VITE_FIREBASE_PROJECT_ID",
);

export const ENABLE_SERVICE_WORKER = asBoolean(
  import.meta.env.VITE_ENABLE_SERVICE_WORKER,
  "VITE_ENABLE_SERVICE_WORKER",
);

export const INTEGRATION_HOOKS_REDIRECT_URL = asString(
  import.meta.env.VITE_INTEGRATION_HOOKS_REDIRECT_URL,
  "VITE_INTEGRATION_HOOKS_REDIRECT_URL",
);

export const ENDEAR_CHECKOUT_CHANNEL_ID = asString(
  import.meta.env.VITE_ENDEAR_CHECKOUT_CHANNEL_ID,
  "VITE_ENDEAR_CHECKOUT_CHANNEL_ID",
);

export const PARTYKIT_HOST = asStringOr(
  import.meta.env.VITE_PARTYKIT_HOST,
  "VITE_PARTYKIT_HOST",
);

export function asString<Value extends string = string>(
  val: string | boolean | undefined,
  name: string,
  validate?: Value[],
): Value {
  if (typeof val !== "string") {
    throw new Error(`${name} is not a string`);
  }

  if (validate && !validate.includes(val as Value)) {
    throw new Error(`${name} is not one of ${validate.join(", ")}`);
  }

  return val as Value;
}

export function asStringOr<DefaultValue>(
  val: string | boolean | undefined,
  defaultValue: DefaultValue,
): string | DefaultValue {
  if (typeof val !== "string") {
    return defaultValue;
  }

  return val;
}

function asBoolean(val: string | boolean | undefined, name: string): boolean {
  if (typeof val === "undefined") {
    throw new Error(`${name} is not a string or boolean`);
  }

  return val === true || val === "true" ? true : false;
}

export enum FeatureFlagEnvironment {
  Dev = "DEV",
  Global = "GLOBAL",
  Stg = "STG",
  Prd = "PRD",
}

function constructFeatureFlagsForEnvironment<K extends string>(
  flagsList: K[],
): { environment: FeatureFlagEnvironment; flag: K }[] {
  const requestedFeatureFlags: {
    environment: FeatureFlagEnvironment;
    flag: K;
  }[] = [];

  flagsList.forEach((flag) => {
    switch (DOPPLER_CONFIG) {
      case "dev":
      case "dev_preview":
      case "dev_tunnel": {
        requestedFeatureFlags.push({
          flag,
          environment: FeatureFlagEnvironment.Dev,
        });
        break;
      }
      case "stg":
      case "prd_loc": {
        requestedFeatureFlags.push({
          flag,
          environment: FeatureFlagEnvironment.Stg,
        });
        break;
      }
      case "prd": {
        requestedFeatureFlags.push({
          flag,
          environment: FeatureFlagEnvironment.Prd,
        });
        break;
      }
      default: {
        assertNever(DOPPLER_CONFIG);
      }
    }
  });

  return requestedFeatureFlags;
}

export function redirectToLogin() {
  const url = new URL("/admin/auth/login", APPLICATION_URL);
  return iframeNavigate(url.toString().replace(url.origin, ""));
}

export function redirectToSwitchTeams() {
  const url = new URL("/admin/auth/teams", APPLICATION_URL);
  return iframeNavigate(url.toString().replace(url.origin, ""));
}
