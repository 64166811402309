import type { MessageWithId } from "@endearhq/service-messaging";
import axios from "axios";

import { DOPPLER_CONFIG, RELEASE } from "../../conf";
import type { Timestamp } from "../firebase";
import { request } from "./utils";

export type ConvertTimestampToJson<T extends object> = {
  [P in keyof T]: T[P] extends Timestamp
    ? { _nanoseconds: number; _seconds: number }
    : T[P] extends object
      ? ConvertTimestampToJson<T[P]>
      : T[P];
};

export interface ApiMessagesList {
  query: {
    channel_id: string;
    limit: number;
    after?: string;
  };
  response: {
    "200": {
      channel_id: string;
      messages: ConvertTimestampToJson<MessageWithId>[];
      done: boolean;
      cursor: string | null;
    };
  };
}

const api = axios.create({
  baseURL: "/api/messages",
  validateStatus: () => true,
  timeout: 10000,
  headers: {
    "X-Endear-Version": RELEASE,
    "X-Endear-Env": DOPPLER_CONFIG,
    "X-Endear-Application": "web",
  },
});

export const apiListMessages = async (query: ApiMessagesList["query"]) =>
  request(async () =>
    api.get<ApiMessagesList["response"]["200"]>("/list", {
      params: query,
    }),
  );
